<template>
  <u-modal v-model="internalValue">
    <template
      v-if="
        exhibitorForm
          && Object.keys(exhibitorForm.input?.properties ?? {}).length
      "
    >
      <h5 class="px-4 pt-4">
        Exhibitor information
      </h5>
      <dynamic-form
        v-model="exhibitorForm.state"
        :input="exhibitorForm.input"
        :schema="exhibitorForm.schema"
        class="p-4"
      />
    </template>
    <dynamic-form
      v-if="form"
      v-model="form.state"
      :input="form.input"
      :schema="form.schema as any"
      class="p-4"
      @submit="onSubmit"
    >
      <div class="flex gap-4 pt-8">
        <u-button
          color="white"
          @click="internalValue = false"
        >
          Close
        </u-button>
        <u-button
          type="submit"
          :loading="loading"
          :disabled="
            !form?.isValid
              || !exhibitorForm?.isValid
              || (cartEntry
                && useDeepEqual(form.state, cartEntry.info_submitted)
                && useDeepEqual(
                  exhibitorForm.state,
                  cartEntry.exhibitor_info_submitted,
                ))
              || loading"
        >
          {{ cartEntry ? 'Save changes' : 'Add to cart' }}
        </u-button>
      </div>
    </dynamic-form>
  </u-modal>
</template>

<script lang="ts" setup>
import useForm, { type SchemaUiContentful } from '@tarcltd/form-vue'

const props = withDefaults(
  defineProps<{
    /**
     * The status of the modal.
     */
    modelValue: boolean
    /**
     * The subuser to add the entry for.
     */
    subuserId: string
    /**
     * The show object event.
     */
    event: PortalEvent
    /**
     * The show object show.
     */
    show: PortalShow
    /**
     * The show exhibitor.
     */
    exhibitor?: PortalExhibitor
    /**
     * Any previous show objects for this show and subuser.
     */
    showObjects?: PortalShowObject[]
    /**
     * Any cart show objects for this show and subuser.
     */
    cartShowObjects?: PortalCartShowObject[]
    /**
     * The cart entry to edit.
     */
    cartEntry?: PortalCartShowObject
    /**
     * The app loading state.
     */
    loading?: boolean
  }>(),
  {
    exhibitor: undefined,
    showObjects: () => [],
    cartShowObjects: () => [],
    cartEntry: undefined,
    loading: false,
  },
)
const emit = defineEmits(['update:modelValue', 'addToCart'])
const exhibitorForm = ref<ReturnType<typeof useForm>>()
const form = ref(useForm(props.show.manifest))
const internalValue = computed({
  get: () => props.modelValue,
  set(value) {
    emit('update:modelValue', value)
  },
})

function onSubmit() {
  emit('addToCart', {
    exhibitor: props.exhibitor,
    old: props.cartEntry,
    new: {
      id: props.cartEntry?.id ?? useV4(),
      event_id: props.event.id,
      show_id: props.show.id,
      subuser_id: props.subuserId,
      type: 'show_object',
      basis: -1,
      division: (props.show.manifest.properties._title as SchemaUiContentful)?.content,
      exhibitor_info_submitted: exhibitorForm.value?.state ?? {},
      info_submitted: form.value?.state ?? {},
    },
  } as {
    exhibitor: PortalExhibitor
    old?: PortalCartShowObject
    new: PortalCartShowObject
  })

  internalValue.value = false
}

watch(
  () => props.event,
  (value) => {
    if (value) {
      exhibitorForm.value = useForm(
        props.event?.metadata?.info ?? {
          type: 'object',
          properties: {},
          required: [],
        },
      )

      if (props.exhibitor) {
        for (const [key, value] of Object.entries(props.exhibitor.metadata.info)) {
          exhibitorForm.value.state[key] = value
        }
      }

      if (props.cartEntry) {
        for (const [key, value] of Object.entries(
          props.cartEntry.exhibitor_info_submitted,
        )) {
          exhibitorForm.value.state[key] = value
        }
      }
    }
    else {
      exhibitorForm.value = useForm({
        type: 'object',
        properties: {},
        required: [],
      })
    }
  },
  { immediate: true, deep: true },
)

watch(
  [
    () => props.modelValue,
    () => props.show.manifest,
  ],
  ([_, value]) => {
    if (value) {
      form.value = useForm(value)
    }
    else {
      form.value = useForm({
        type: 'object',
        properties: {},
        required: [],
      })
    }
  },
  { deep: true },
)

watch(
  [
    () => form.value?.input,
    () => props.cartEntry,
  ],
  ([
    _,
    updatedCartEntry,
  ]) => {
    if (!form.value) {
      return
    }

    if (updatedCartEntry) {
      for (const [key, previousValue] of Object.entries(
        updatedCartEntry.info_submitted,
      )) {
        form.value.state[key] = previousValue
      }
    }
  },
  { immediate: true, deep: true },
)

watch(
  [

    () => exhibitorForm.value?.input,
    () => props.cartEntry,
    () => props.cartShowObjects,
    () => props.showObjects,
    () => props.exhibitor,
  ],
  ([
    _,
    updatedCartEntry,
    updatedCartShowObjects,
    updatedShowObjects,
    updatedExhibitor,
  ]) => {
    if (!exhibitorForm.value) {
      return
    }

    if (updatedExhibitor) {
      for (const [key, value] of Object.entries(updatedExhibitor.metadata.info)) {
        exhibitorForm.value.state[key] = value
      }
    }

    if (updatedCartEntry) {
      for (const [key, value] of Object.entries(
        updatedCartEntry.exhibitor_info_submitted,
      )) {
        exhibitorForm.value.state[key] = value
      }
    }
    else if (updatedCartShowObjects) {
      for (const item of updatedCartShowObjects) {
        if (item.subuser_id !== props.subuserId || props.event.id !== item.event_id) {
          continue
        }

        for (const [key, value] of Object.entries(
          item.exhibitor_info_submitted,
        )) {
          exhibitorForm.value.state[key] = value
        }
      }
    }

    if (updatedShowObjects) {
      for (const item of updatedShowObjects) {
        if (item.subuser_id !== props.subuserId || props.event.id !== item.event_id) {
          continue
        }

        for (const [key, value] of Object.entries(item.metadata ?? {})) {
          if (key in exhibitorForm.value.input.properties) {
            exhibitorForm.value.state[key] = value
          }
        }
      }
    }
  },
  { immediate: true, deep: true },
)
</script>
